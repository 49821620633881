@mixin loading {
  text-transform: none;
  margin: 1em 0;
  @include breakpoint($bp--medium-up) {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
  }
  background: url(/media/dev/global/icon_loading.gif) no-repeat center center;
  * {
    opacity: 0.8;
    filter: alpha(opacity=80);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  }
}

.pg_wrapper {
  padding: 0 10px 10px 10px;
}

@include breakpoint($bp--small-down) {
  .checkout__content {
    padding: 1em;
  }
}

input.error,
select.error,
textarea.error,
.select2-container.error {
  color: $color--error !important;
  border-color: $color--error !important;
}

.checkout__content .panel.shipping-panel .address-form .select2-container.error,
.checkout__content .panel.shipping-panel .address-form .select2-container.checkedEmpty,
.checkout__content .panel.payment-panel .select2-container.error,
.checkout__content .panel.payment-panel .select2-container.checkedEmpty,
.adpl input[type='email'].error,
.adpl input[type='tel'].error,
.adpl input[type='password'].error,
.adpl input[type='text'].error {
  color: $color--error !important;
  border-color: $color--error !important;
}

section {
  .panel {
    h2 {
      @if $fonts_update == false {
        font-size: 2rem;
      }
    }
    .messages {
      color: $color--error;
      border-bottom: 0;
      background-color: $color--white;
      padding: 0;
    }
  }
}

.checkout {
  .loading {
    @include loading;
  }
  .mobile-breadcrumb {
    display: none;
  }
  .cart-item__desc {
    @extend .product__desc;
    .remove_link {
      position: relative;
    }
  }
  .cart-item {
    .cart-item__total {
      display: block;
    }
    &__qty {
      select[name='QTY'],
      .select2-container {
        border-width: 1px;
      }
    }
  }
  .cart-item__replenishment {
    clear: both;
    @include breakpoint($bp--medium-up) {
      #alter_replenishment {
        width: 30%;
      }
    }
  }
  #promo-panel {
    @include breakpoint($bp--small-down) {
      padding-bottom: 1em;
    }
  }
  &__content {
    .checkout__return-user-info {
      display: none;
    }
    .viewcart {
      &-header {
        display: block;
        position: relative;
        text-align: center;
      }
    }
    .checkout__header {
      &-item-count {
        display: none;
      }
    }
    .viewcart-panel {
      &__title {
        font-size: 30px;
      }
      &__content {
        .signin-to-see-cart {
          margin-top: 10px;
        }
      }
      .cart-header {
        @include breakpoint($bp--medium-down) {
          display: none;
        }
      }
      .cart-header {
        .products {
          @extend .cart-header__title;
        }
        .column {
          @extend .cart-header__title;
        }
      }
      @media (max-width: 48em) {
        .cart-item__price {
          clear: right;
          float: left;
          width: 35%;
        }
        .mobile_hidden {
          display: block !important;
        }
        .cart-item__qty {
          margin: 20px 5px;
        }
        .cart-item__total {
          margin-left: 0px;
          float: right;
        }
        .cart-item__replenishment {
          .replenishment__description {
            display: block;
          }
        }
        .cart-item__price,
        .cart-item__total {
          font-size: 13px;
        }
      }
      .viewcart-buttons-panel {
        .go-shopping {
          display: none;
        }
        @include breakpoint($bp--small-down) {
          display: block;
          a.continue-checkout {
            margin: 0 !important;
          }
          .continue-shopping {
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 0px;
          }
          .continue-checkout-btns {
            width: 100%;
          }
        }
        .continue-buttons {
          .btn {
            width: 100%;
          }
        }
      }
    }
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        display: block;
      }
    }
    .bottom-viewcart-buttons,
    .viewcart-buttons-panel {
      .continue-buttons {
        display: inline;
        .go-shopping {
          float: right;
        }
      }
      .continue-shopping,
      .continue-checkout {
        float: left;
      }
      .continue-checkout-btns {
        float: right;
        width: 25%;
        .btn {
          width: 100%;
        }
      }
      .continue-checkout {
        margin: 0;
      }
    }
    .offer-code-panel {
      input {
        width: 75%;
        margin-right: 0px;
        display: inline-block;
        vertical-align: top;
      }
      .form-submit {
        width: 24%;
        float: right;
      }
    }
    .delivery_options {
      &__content {
        margin-bottom: 2em;
        #delivery_options_select div {
          margin-right: 8px;
          width: 49% !important;
          float: left;
        }
      }
      &-title {
        margin-bottom: 0px;
      }
    }
    .recommended-products {
      &-panel {
        header {
          text-align: center;
          border-bottom: 1px solid $color--gray--lightest;
        }
        &__title {
          font-size: 30px;
        }
        &__content {
          .recommended-product-items {
            margin-top: 10px;
            .description {
              .info {
                height: 14.5em;
                .formatted_price {
                  bottom: 2em;
                }
                .formatted_unit_price {
                  bottom: 1em;
                }
                .product_subline {
                  @extend .product__mini-detail;
                }
              }
            }
          }
        }
      }
    }
    .shipping-panel {
      .new-address {
        .form-item,
        .form_element {
          width: 49%;
          padding-right: 1%;
          display: inline-block;
          margin-bottom: 1em;
          input[type='email'] + label:before,
          input[type='tel'] + label:before,
          input[type='password'] + label:before,
          input[type='text'] + label:before {
            content: attr(placeholder);
          }
        }
        .postal_code_container {
          display: inline-block;
          width: 100%;
          position: relative;
          input {
            width: 48.5%;
            @include breakpoint($bp--small-down) {
              width: 30%;
              float: none;
            }
            float: left;
          }
          .fieldset-note {
            left: 48%;
            margin-left: 16px;
            float: left;
            width: 23% !important;
            @include breakpoint($bp--small-down) {
              width: 58% !important;
              position: absolute;
              left: 130px;
              top: 0;
            }
          }
          .address_lookup_submit {
            @include btn;
            width: 90%;
            display: inline-block;
          }
          .lookup_submit_note {
            right: 30px !important;
            margin-top: 5px;
            display: block;
            float: left;
            width: 22% !important;
            @include breakpoint($bp--small-down) {
              margin-top: 10px;
              width: 100% !important;
              clear: both;
            }
          }
          .lookup_results_container {
            clear: both;
          }
        }
        .phones {
          .phone_requirements {
            margin: 5px 0px 5px 0px;
          }
        }
        .name-fields {
          .form-item.first-name {
            margin-right: 1%;
            float: right;
          }
          .form-item.last-name {
            float: left;
          }
        }
      }
      .shipping-edit-content {
        .gift-options {
          &__content {
            .gift-options {
              .card-message {
                .field {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
        select[name='SHIP_ADDRESS_ID'],
        select[name='BILLFORM_STATE'] {
          border-width: 1px;
        }
        .edit-shipping-radio,
        .edit-billing-radio {
          width: 100%;
          float: left;
        }
        @include breakpoint($bp--medium-up) {
          #address {
            #profile-postal {
              label {
                width: 52%;
                float: left;
              }
              .fieldset-note {
                position: absolute;
                width: 15%;
              }
              .lookup_submit_note {
                position: absolute;
                width: 16%;
                right: 34em;
              }
            }
          }
        }
        .continue-button-wrapper {
          clear: both;
        }
      }
      .shipping-address-display,
      .billing-address-display,
      .gift-options-display,
      .delivery-options-display {
        float: left;
        width: 50%;
      }
    }
    .registration-panel {
      &.panel.finished.disabled {
        border-bottom: 0px;
      }
      #checkout_registration_panel {
        .checkout-registration {
          width: 65%;
          @include breakpoint($bp--small-down) {
            width: 100%;
          }
          &__password {
            margin-bottom: 15px;
            input {
              margin-bottom: 15px;
            }
            label {
              display: block;
            }
          }
          &__password-hint {
            div {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    .payment-panel {
      .payment-edit-content {
        .payment-form {
          margin-top: 40px;
          .form-item {
            width: 60%;
            @include breakpoint($bp--small-down) {
              width: 100%;
            }
          }
          .expiry {
            width: 45%;
            @include breakpoint($bp--small-down) {
              width: 100%;
            }
            display: inline-block;
            select,
            div {
              width: 16em;
              display: inline-block;
              @include breakpoint($bp--small-down) {
                width: 154px;
              }
            }
          }
          .cvv {
            display: inline-block;
            width: 11em;
            @include breakpoint($bp--small-down) {
              width: 100%;
            }
          }
        }
        .payment-container {
          &.select-card {
            width: 60%;
            @include breakpoint($bp--small-down) {
              width: 100%;
            }
          }
          .picker {
            width: 45%;
            @include breakpoint($bp--small-down) {
              width: 100%;
            }
            float: left;
          }
          clear: both;
          &__option--rakuten {
            & ~ .picker {
              width: 100%;
              label {
                width: 80%;
              }
            }
          }
        }
        #checkout_billing {
          .form-container {
            margin-top: 40px;
          }
        }
      }
      .continue-button-wrapper {
        &.rakuten {
          input {
            width: 300px;
            background: no-repeat center/100% url(/media/export/cms/checkout/rpay_btn.png);
            color: transparent;
            height: 40px;
          }
        }
      }
    }
    #payment-panel .cvv input {
      width: 11em;
      @include breakpoint($bp--small-down) {
        width: 100%;
      }
    }
    @include breakpoint($bp--small-down) {
      .order-summary {
        &-panel {
          &__title {
            font-weight: bold;
          }
          font-weight: bold;
          font-size: 12px;
        }
        &__content {
          .label {
            font-size: 12px;
          }
          .value {
            width: 33.33333%;
            font-size: 12px;
          }
        }
      }
      #shipping-panel {
        &-edit {
          font-size: 12px !important;
        }
      }
    }
    .confirmation-panel {
      &__actions {
        padding: 0px;
        .return-link-container {
          .button {
            color: $color--white;
          }
          margin-top: 20px;
        }
      }
    }
  }
  .checkout__content #offer-code-panel {
    background-color: $color--white;
    padding: 0;
    border-bottom: 0;
    margin-top: 0;
  }
  .checkout__sidebar #samples-panel .product-list {
    max-height: 585px;
  }
  .checkout__sidebar #samples-panel .samples-panel__content {
    margin-top: 0;
  }
  .checkout__sidebar #links-panel {
    margin-top: 12px;
  }
  #shipping-panel-edit,
  #shopping-bag-edit {
    color: $color--black;
    font-size: 15px;
    text-align: center;
    padding: 0;
    height: 12px;
    border-width: 1px solid;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 1.5px;
    border-bottom: 1px solid $color--gray--lightest;
    min-width: auto !important;
  }
  #gift-options-h {
    display: inline-block;
  }
  &__sidebar {
    .product-list {
      .product {
        width: 100%;
        .product-img {
          .view-larger {
            display: none;
          }
        }
        .details {
          @extend .product__desc;
          .product-name {
            font-weight: normal;
            font-size: 12px;
            line-height: 1.5;
            a {
              border-bottom: none;
            }
          }
          .add-to-cart input[type='submit'] {
            width: 140px;
            height: 40px;
            margin-top: 10px;
            line-height: 30px;
            min-width: 1em !important;
            clear: none;
            float: left;
            padding: 0;
            color: $color--white;
            background-color: $color--black;
            border: solid 1px $color--black;
            font-size: 12px;
          }
        }
      }
    }
    .tab-bar {
      display: none;
    }
    @include breakpoint($bp--small-down) {
      .need-help-panel,
      .samples-pane,
      .favorites-pane,
      .past-purchases-pane {
        display: none;
      }
      .viewcart-buttons-panel {
        background: $color--white;
        display: block;
      }
      .bottom-viewcart-buttons {
        .viewcart-buttons-panel {
          @include breakpoint($bp--small-down) {
            .continue-shopping {
              display: none;
            }
            .continue-checkout-btns {
              .btn {
                width: 100%;
              }
            }
          }
        }
      }
      #offer-code-panel {
        background: $color--white;
        .offer-code-panel__content {
          #offer_code {
            .form-submit {
              width: 100%;
              margin-top: 5%;
            }
          }
        }
      }
      #recommended-products-panel {
        text-align: center;
        .recommended-product-items {
          .recommended-item {
            padding-bottom: 20px;
            .info.column {
              min-height: 100px;
            }
          }
        }
      }
    }
    .samples-panel {
      margin-top: -0.7em;
      &__title {
        text-align: left;
      }
      &__submit-container {
        display: none;
      }
      .samples-buttons {
        display: none;
      }
      .remaining {
        background-color: $color--gray--white;
        padding: 0 1.2em 1em 1em;
        margin-bottom: 1em;
      }
      .samples {
        .offer {
          h3 {
            display: none;
          }
          .product-list {
            .product {
              width: 100%;
              .product-img {
                .view-larger {
                  display: none;
                }
              }
              .details {
                @extend .product__desc;
              }
            }
          }
        }
      }
      header {
        border-bottom: 0;
      }
    }
    .links-panel {
      margin-top: 10px;
    }
    #viewcart-panel {
      .cart-item__total {
        width: auto;
        padding-right: 0;
        margin-left: 55.33333%;
        line-height: 20px;
      }
      .cart-item__price {
        display: none;
      }
      .cart-item {
        &__thumb {
          width: 50%;
          float: left;
          margin-right: -100%;
          clear: none;
          padding-right: 1em;
        }
        &__desc {
          width: 50%;
          float: right;
          margin-left: 0;
          margin-right: 0;
          clear: none;
          padding-right: 0;
          clear: right;
        }
        &__replenishment {
          clear: both;
        }
      }
      .content {
        margin-top: 10px;
      }
      .viewcart-panel__title {
        width: 50%;
        float: left;
      }
      .checkout__header-item-count {
        width: 30%;
        float: left;
        @if $fonts_update == true {
          margin-top: 0.5em;
        } @else {
          margin-top: 1em;
        }
      }
      #shopping-bag-edit {
        margin-top: 1em;
      }
      .viewcart-header {
        @extend .clearfix;
      }
    }
    .order-summary {
      &-panel {
        font-weight: bold;
        font-size: 12px;
        .total {
          padding: 18.5px 10px !important;
        }
      }
      &__content {
        .label {
          font-size: 12px;
        }
        .value {
          width: 33.33333%;
          font-size: 12px;
        }
      }
    }
    .offer-code-panel {
      #offer_code {
        text-align: center;
        .form-submit {
          margin-top: 5px;
          width: 172px;
        }
      }
    }
    .cart-header {
      display: none;
    }
    .delivery_options {
      &__content {
        #delivery_options_select .select2-container {
          background-color: $color--gray--white;
        }
      }
    }
  }
  #samples-panel {
    .sample-select-button {
      display: block;
      width: 145px;
      margin: 5px 0px 0px 0px;
    }
  }
  #gift-max-char,
  .choose-payment-method {
    display: none;
  }
  .card-message {
    #gift-max-char {
      display: block;
    }
  }
  .checkout__sidebar #offer-code-panel .offer-code-panel__content input[type='text'] {
    background-color: $color--gray--white;
  }
  #checkout_billing {
    #continue-btn {
      margin-top: 10px;
    }
  }
  .payment-details {
    padding-bottom: 20px;
  }
  #checkout_token_payment {
    #continue-btn {
      clear: both;
    }
  }
  .birth-month-container {
    .select-birth-month {
      @include breakpoint($bp--large-up) {
        width: 25% !important;
      }
    }
    @include breakpoint($bp--medium-down) {
      select {
        border: 1px solid $color--black;
      }
    }
    .birthday-panel__title {
      font-size: 14px;
    }
    .checkout-birthday {
      &__descriptive-text {
        padding-top: 10px;
        font-size: 12px;
      }
    }
  }
}

#confirmation-page .confirmation-panel__actions {
  padding: 0px;
  margin: 0px;
}

.adpl input[type='email'] + label:before,
.adpl input[type='tel'] + label:before,
.adpl input[type='password'] + label:before,
.adpl input[type='text'] + label:before {
  content: attr(placeholder);
}

.adpl input[type='email'].js-label-mode + label:before,
.adpl input[type='tel'].js-label-mode + label:before,
.adpl input[type='password'].js-label-mode + label:before,
.adpl input[type='text'].js-label-mode + label:before {
  content: attr(alt);
}

.samples-panel {
  @extend .samples-page;
  &__title {
    text-align: center;
  }
  &__content {
    position: relative;
    .loading {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: 0 0 0 -25px;
      width: 50px;
      text-align: center;
      z-index: 999;
    }
    .samples-buttons {
      float: right;
      width: 100%;
      .return-link {
        float: left;
      }
      .add-button {
        margin-top: 10px;
        float: right;
        .btn {
          min-width: 228px;
        }
      }
    }
    .samples-buttons.top {
      display: none;
    }
    .samples {
      .offer {
        h3 {
          display: none;
        }
        .remaining {
          text-align: center;
          clear: both;
        }
        .product-list {
          &:after {
            content: '';
            display: table;
            clear: both;
          }
          .product {
            float: left;
            margin-right: 4%;
            text-align: center;
            width: 29%;
            .product-img {
              overflow: hidden;
              @include breakpoint($width-medium $width-xlarge) {
                min-height: 281px;
              }
              .view-larger {
                display: none;
              }
            }
            .sample-select-button {
              width: 120px;
              height: 30px;
              margin-top: 10px;
              line-height: 30px;
              min-width: 1em !important;
              clear: none;
              float: right;
              padding: 0 15px;
              color: $color--black;
              background-color: $color--gray--white;
              border: solid 1px $color--black;
              &.selected {
                color: $color--white;
                background-color: $color--black;
              }
            }
            .sample-select-button:hover {
              text-decoration: none;
              border-bottom: 2px solid black;
            }
          }
        }
      }
    }
  }
}

.samples-page {
  .viewcart-buttons-panel {
    .content {
      clear: both;
      .edit-cart {
        float: left;
        width: 40%;
      }
      .continue-buttons {
        float: right;
        width: auto;
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.samples-overlay #samples-panel.loading,
.samples-page #checkout_samples.loading {
  @include loading;
  opacity: 0.3;
  position: initial;
}

.samples-overlay #samples-panel .product-list .product {
  .product-img {
    min-height: 250px;
  }
  ul.skus li {
    width: 100%;
    float: left;
    bottom: 0px;
    padding: 0.2em 0em 0em 1em;
    min-width: 1em;
    border-top: 1px solid $color--gray--lightest;
    border-bottom: 1px solid $color--gray--lightest;
    min-height: 5em;
  }
}

#order-summary-panel {
  .order-summary__content {
    .cod_fee {
      clear: both;
    }
  }
}
/* For mobile only */
@media (max-width: 767px) {
  .opc__footer {
    margin: 0;
    padding-bottom: 8em;
    background-color: white;
    text-align: center;
  }
  .continue-sticky,
  #continue-sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0 15px 15px 15px;
    box-shadow: 0px -3px 6px #cccccc;
    z-index: 999;
  }
  .viewcart {
    #add-samples-message {
      margin-top: 12px;
    }
    .continue-sticky {
      margin-bottom: 0px;
      width: 100%;
    }
    #samples-panel {
      .opc__footer {
        display: none;
      }
      #continue-sticky {
        position: unset;
        width: 180px;
      }
    }
  }
  .samples {
    section.viewcart-buttons-panel.panel {
      display: none;
    }
    .opc__footer {
      padding-top: 75px;
    }
    #samples-panel {
      .sample-select-button {
        width: 100px;
      }
    }
  }
  .index {
    .opc__footer {
      padding-top: 75px;
    }
  }
  .confirm {
    .opc__footer {
      padding-top: 75px;
    }
    .site-footer {
      display: none;
    }
    .site-header__extra {
      a#mobile-nav {
        display: none;
      }
    }
    .header-search__icon {
      display: none;
    }
    .site-header__suffix {
      display: none;
    }
  }
  .shipping-edit-content {
    .add-address {
      input:focus {
        position: relative;
      }
    }
  }
}

@media (max-width: 770px) {
  .checkout__content {
    .cart-item {
      .cart-item__price {
        margin-right: -20%;
      }
      .cart-item__qty {
        margin-top: 5px;
        margin-left: 25px;
      }
      .cart-item__total {
        margin-right: 0%;
      }
    }
  }
}

@media (max-width: 820px) {
  #offer-code-panel {
    .checkout__panel-title {
      .title-icon {
        @include icon(arrow--down);
        position: relative;
        float: right;
        color: black;
        top: -5px;
      }
    }
    .checkout__panel-title.open {
      .title-icon {
        @include icon(arrow--up);
      }
    }
  }
  .recommended-product-items {
    .recommended-item {
      .info {
        .formatted_price {
          position: unset;
        }
      }
    }
  }
}

@include breakpoint($bp--small-down) {
  .viewcart {
    .checkout__content {
      .cart-item {
        .cart-item__qty {
          margin-top: 15px;
          margin-left: 80px;
        }
        .cart-item__qty.loading {
          position: unset;
        }
      }
    }
    .continue-buttons {
      a.btn.go-shopping {
        width: 228px;
        margin-right: 50px;
        margin-top: 10px;
      }
    }
  }
}

#return-user {
  .form-item {
    ::-webkit-input-placeholder {
      color: white;
    }
    /* Firefox 4-18 */
    :-moz-placeholder {
      color: white;
    }
    /* Firefox 19-50 */
    ::-moz-placeholder {
      color: white;
    }
    /* Internet Explorer */
    :-ms-input-placeholder {
      color: white;
    }
    /* Edge */
    ::-ms-input-placeholder {
      color: white;
      opacity: 1;
    }
  }
}

.adpl {
  #checkout_registration_panel {
    input[type='password'] + label {
      line-height: 3em;
      font-size: 13px;
    }
    .checkout-registration__password {
      span.label-content {
        display: none;
      }
    }
  }
}

.checkout__content {
  .cart-item {
    .cart-item__qty.loading {
      position: absolute;
      margin-top: 10px;
      left: 65%;
    }
  }
}

.sign-in-panel.adpl {
  input[type='password'].js-label-mode + label:before {
    content: attr(data-required) attr(alt);
  }
  input[type='password'] + label:before {
    content: attr(data-required) attr(placeholder);
  }
}

.checkout__content {
  .order-summary__content .label {
    @include breakpoint($bp--medium-up) {
      width: 24.66667%;
    }
  }
}

@include breakpoint($bp--medium-down) {
  .active-panel-registration {
    #links-panel {
      display: none;
    }
  }
  .column.right {
    #viewcart-panel {
      .viewcart-panel__title {
        width: 45%;
      }
      .checkout__header-item-count {
        width: 40%;
      }
      a#shopping-bag-edit {
        float: right;
        @if $fonts_update == false {
          margin-top: 1.3em;
        }
      }
    }
  }
}

@include breakpoint($bp--medium-up) {
  .checkout__header-item-count {
    &:before {
      content: '：';
    }
  }
}

@if $fonts_update == true {
  .checkout__sidebar {
    #viewcart-panel {
      .viewcart-header {
        .item-count {
          top: unset;
        }
      }
    }
  }
}
