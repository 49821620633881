.viewcart {
  .gift-options-panel {
    .giftwrap {
      .sub-section {
        .btn {
          width: auto;
        }
      }
    }
  }
}
