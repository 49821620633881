/* Added For Pre-Order Enhancement */
.product--preorder {
  .BVRRRatingSummaryLinkWrite,
  .BVRRRatingSummaryLinkWriteFirst {
    a {
      pointer-events: none;
      opacity: 0.6;
      cursor: default;
    }
  }
  .product-full {
    &__review-snippet {
      .pr-snippet-write-review-link {
        pointer-events: none;
        opacity: 0.6;
        cursor: default;
      }
    }
  }
}

.preorder-panel {
  border-top: 1px solid $color--gray--lightest;
  &__subhead {
    display: block;
  }
  &__viewcart-text {
    padding-top: 15px;
    .details-link {
      text-transform: none;
    }
  }
  &__order-summary {
    margin-bottom: 10px;
  }
}

.grid--mpp__item {
  .product--preorder {
    .product--teaser {
      &__msg-preorder {
        .preorder_details {
          display: none;
        }
      }
    }
  }
}
/* Viewcart Background change for preorder */
.viewcart {
  .viewcart-panel,
  .order-summary-panel {
    @include swap_direction(padding, 1em 5px 1em 10px);
  }
  .delivery_options {
    &__content {
      select {
        &[disabled] {
          opacity: 0.3;
        }
      }
    }
  }
  .preorder-panel {
    background: $color--gray--white;
    &__viewcart-text {
      width: 75%;
    }
    .qty {
      .select2-container {
        .select2-choice {
          background: $color--gray--white;
          .select2-arrow {
            background: $color--gray--white;
          }
        }
      }
    }
    &__order-summary {
      background: $color--gray--white;
      .total {
        &.label,
        &.value {
          background: $color--gray--white;
        }
      }
    }
  }
}
/* Side Panel preorder */
.checkout__sidebar {
  .preorder-panel {
    &.side-panel {
      .cart-item__pretext {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        width: 65%;
        float: #{$rdirection};
        clear: #{$rdirection};
      }
    }
  }
}
/* Shipping Page preorder */
.preorder-msg {
  &__ship-carddetails {
    margin-bottom: 15px;
  }
  &__cod {
    padding-left: 51%;
  }
  &__gmo {
    width: 50%;
    padding-left: 4px;
  }
  &__gmo-user {
    width: 100%;
  }
  &__gift-wrap {
    width: 80%;
  }
}
/* Order History preorder */
.account-order-history {
  .preorder-history-panel {
    .order-info {
      &.past-purchases__item {
        .product-header {
          .product {
            width: 43%;
            margin-right: 0;
          }
          .quantity {
            width: 17%;
            margin-left: 0;
            margin-right: 0;
          }
          .price {
            width: 15%;
            float: #{$ldirection};
            margin-right: 0;
          }
          .preorder-details {
            width: 18%;
            float: #{$ldirection};
          }
        }
        .product--teaser {
          .product__qty {
            margin-left: 43%;
          }
        }
      }
    }
    .status-url {
      display: block;
    }
  }
}

.preorder-text {
  &__content {
    color: $color--red;
  }
}

.shipments-list {
  .product-header {
    h6.preorder-details {
      .preorder-detail-item-link {
        color: $color--blue;
        text-transform: none;
      }
    }
  }
}

.preorder-history {
  &__header {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &__subheader {
    @include swap_direction(margin, 5px 0 10px 0);
    font-size: 14px;
    text-align: center;
  }
}
