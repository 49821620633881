/**
 * @file common.scss
 *
 * \brief Styles for PC and for mobile
 */

.prod_inv_status-3 .coming-soon.display__notify-me {
  .grid--mpp__item & {
    display: block;
  }
}

.product-full__details-body {
  ul {
    margin-#{$ldirection}: 11px;
  }
}

.product-full {
  &__overview-trigger span {
    font-size: 13px;
    letter-spacing: 0.05em;
    font-weight: 700;
  }
  &__name,
  &__price-details {
    font-size: 1.3em;
    font-weight: 700;
  }
  &__details {
    letter-spacing: 0.05em;
    &-title {
      letter-spacing: 0.05em;
      font-weight: 700;
    }
  }
  &__size {
    font-weight: 400;
  }
  &--enhanced {
    &.product-full {
      .product-full__details-container {
        .product-full__shades-filter-button {
          font-weight: 700;
        }
      }
    }
  }
}

.spp-reviews__title {
  font-weight: 700;
}

@if $fonts_update == true {
  body {
    .mac-faq__q {
      font-weight: 700;
    }
    .site-container {
      .product--teaser {
        .product {
          &__link-to-spp {
            font-weight: 700;
          }
        }
      }
    }
    .shade-picker__trigger {
      font-weight: 700;
    }
  }
}
