@font-face {
  font-family: SmartFontUI;
  src: url('#{$netstorage-font-path}' + 'SmartFont/SmartFont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
$meiryo-font-family: 'Meiryo UI', 'ano-bold_regular', 'Yu Gothic UI', '游ゴシック体', YuGothic,
  'Yu Gothic', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳＰゴシック', Helvetica, Arial, sans-serif;
$smart-font-family: 'SmartFontUI', Osaka, 'ＭＳＰゴシック', 'tstar_mono_roundregular', Helvetica, Arial, sans-serif;

$meiryo-font-family_v2: font-name-new('ano-bold_regular'), 'Meiryo UI', 'Yu Gothic UI', '游ゴシック体', YuGothic,
  'Yu Gothic', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳＰゴシック', Helvetica, Arial, sans-serif;
$helvetica-jp-font-family_v2: Helvetica, Osaka, 'ＭＳＰゴシック', 'tstar_mono_roundregular', Arial, sans-serif;

body {
  @if $fonts_update == true {
    font-family: $helvetica-jp-font-family_v2;
  } @else {
    font-family: $smart-font-family;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .btn,
  input[type='submit'],
  b,
  strong,
  .media-block__headline,
  .BVDI_QTTitle,
  .BVRRRootElement input,
  .BVRRReviewTitle,
  .multi-use-tout__title,
  .tout-block-portrait__headline,
  .text-block__headline {
    @if $fonts_update == true {
      font-family: $meiryo-font-family_v2;
    } @else {
      font-family: $meiryo-font-family;
    }
  }
  input,
  .text-block__body,
  .BVRRRootElement,
  select,
  textarea {
    @if $fonts_update == true {
      font-family: $helvetica-jp-font-family_v2;
    } @else {
      font-family: $smart-font-family;
    }
  }
  .site-header {
    @if $fonts_update == true {
      font-family: $meiryo-font-family_v2;
    } @else {
      font-family: $meiryo-font-family;
    }
    .site-navigation__more {
      @if $fonts_update == true {
        font-family: $meiryo-font-family_v2;
      } @else {
        font-family: $headline-font-family;
      }
    }
    .site-header__tools {
      .site-bag__contents {
        @if $fonts_update == true {
          font-family: $helvetica-jp-font-family_v2;
        } @else {
          font-family: $smart-font-family;
        }
        &-title,
        .cart-product-name,
        .cart-product-button,
        &-checkout,
        .cart-product-price,
        &-subtotal {
          @if $fonts_update == true {
            font-family: $meiryo-font-family_v2;
          } @else {
            font-family: $meiryo-font-family;
          }
        }
      }
      .site-my-mac,
      .site-email-signup {
        &__contents {
          @if $fonts_update == true {
            font-family: $helvetica-jp-font-family_v2;
          } @else {
            font-family: $smart-font-family;
          }
          &-title,
          &-submit,
          &-footer-title {
            @if $fonts_update == true {
              font-family: $meiryo-font-family_v2;
            } @else {
              font-family: $meiryo-font-family;
            }
          }
        }
      }
      .site-my-mac__link,
      .cart-product-name .product_rgn_name_below_subline {
        @if $fonts_update == true {
          font-family: $helvetica-jp-font-family_v2;
        } @else {
          font-family: $smart-font-family;
        }
      }
    }
  }
  .site-container {
    .account-page {
      &-none__text,
      &-none__link,
      &__title,
      .orders-list__table th {
        @if $fonts_update == true {
          font-family: $meiryo-font-family_v2;
        } @else {
          font-family: $meiryo-font-family;
        }
      }
    }
    .product--teaser {
      .product {
        &__subline,
        &__name,
        &__price,
        &__link-to-spp,
        &__add-to-bag {
          @if $fonts_update == true {
            font-family: $meiryo-font-family_v2;
          } @else {
            font-family: $meiryo-font-family;
          }
        }
      }
    }
    .product--full {
      .product {
        &__name,
        &__subline,
        &__description-trigger-title,
        &__price,
        &__add-to-bag,
        &__link-to-spp {
          @if $fonts_update == true {
            font-family: $meiryo-font-family_v2;
          } @else {
            font-family: $meiryo-font-family;
          }
        }
      }
    }
    .artistry--title-tile h3,
    .artistry--trending-title-tile h3,
    .artist-tile__header {
      @if $fonts_update == true {
        font-family: $meiryo-font-family_v2;
      } @else {
        font-family: $meiryo-font-family;
      }
    }
    .culture-landing {
      &__tracks__header-title,
      &__teaser-category {
        @if $fonts_update == true {
          font-family: $meiryo-font-family_v2;
        } @else {
          font-family: $meiryo-font-family;
        }
      }
    }
    .checkout {
      .cart-header__title,
      .product__name,
      .product_name,
      &__panel-title,
      .order-summary__content .label,
      .order-summary__content .value,
      .product__price,
      .cart-item__total {
        @if $fonts_update == true {
          font-family: $meiryo-font-family_v2;
        } @else {
          font-family: $meiryo-font-family;
        }
      }
      .confirmation-page {
        .confirmation-panel__confirm-text {
          @if $fonts_update == true {
            font-family: $meiryo-font-family_v2;
          } @else {
            font-family: $meiryo-font-family;
          }
          a {
            @if $fonts_update == true {
              font-family: $meiryo-font-family_v2;
            } @else {
              font-family: $headline-font-family;
            }
          }
        }
      }
      .recommended-product-items {
        .info .formatted_price {
          @if $fonts_update == true {
            font-family: $meiryo-font-family_v2;
          } @else {
            font-family: $meiryo-font-family;
          }
        }
      }
    }
  }
  .site-footer {
    &--bottom {
      @if $fonts_update == true {
        font-family: $meiryo-font-family_v2;
      } @else {
        font-family: $meiryo-font-family;
      }
    }
  }
  .qty_select,
  .waitlist_header,
  .waitlist_message {
    @if $fonts_update == true {
      font-family: $meiryo-font-family_v2;
    } @else {
      font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
    }
  }
  /* Fonts update for culture pages */
  .culture-feature {
    &__carousel-category,
    &__carousel-description {
      @if $fonts_update == true {
        font-family: $helvetica-jp-font-family_v2;
      } @else {
        font-family: $smart-font-family;
      }
    }
  }
  .culture-landing {
    &__teaser-description {
      @if $fonts_update == true {
        font-family: $helvetica-jp-font-family_v2;
      } @else {
        font-family: $smart-font-family;
      }
    }
  }
  .culture-detail {
    &__header-category {
      @if $fonts_update == true {
        font-family: $helvetica-jp-font-family_v2;
      } @else {
        font-family: $smart-font-family;
      }
    }
    .recommended-article-category {
      @if $fonts_update == true {
        font-family: $helvetica-jp-font-family_v2;
      } @else {
        font-family: $smart-font-family;
      }
    }
    &__article-content {
      &__body {
        @if $fonts_update == true {
          font-family: $helvetica-jp-font-family_v2;
        } @else {
          font-family: $smart-font-family;
        }
        &-above,
        &-between,
        &-below {
          b,
          i,
          em,
          blockquote {
            @if $fonts_update == true {
              font-family: $helvetica-jp-font-family_v2;
            } @else {
              font-family: $smart-font-family;
            }
          }
          strong,
          p,
          span {
            @if $fonts_update == true {
              font-family: $helvetica-jp-font-family_v2;
            } @else {
              font-family: $smart-font-family !important;
            }
          }
        }
      }
    }
  }
  /* End font update culture page */
  .smtfnt {
    font-family: $smart-font-family;
  }
}
//Roboto-Mono font replaces the Tstar font
.grid--mpp__item {
  .product--teaser {
    .product_header_details {
      .product_rgn_name_below_subline {
        @if $fonts_update == true {
          font-family: $meiryo-font-family_v2;
        } @else {
          font-family: $roboto-mono_regular;
        }
      }
    }
  }
}

.page--spp-ultra-wide {
  .product--full {
    .product_rgn_name_below_subline {
      @if $fonts_update == true {
        font-family: $meiryo-font-family_v2;
      } @else {
        font-family: $roboto-mono_regular;
      }
    }
  }
}
