.mpp-filter-with-attributes-mobile .filter-by-title {
  display: block;
}

.grid-filter__results-list {
  clear: both;
  .grid-filter__results-item {
    line-height: 17px;
  }
}

.mpp-filter-with-attributes .filter-popup-buttons-mobile {
  height: 70px;
}

.panel {
  &.collapsed,
  &.disabled {
    display: none;
  }
}

.product-full {
  &__title {
    display: block;
    width: 90%;
  }
  &--enhanced {
    .product-full {
      &__tabs-block {
        .accordion {
          &-content {
            .accordion-item {
              .accordion-item__label {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.spp-reviews__title {
  font-size: 27px;
}

.product-foundation-vto {
  &__overlay-button {
    background-position: 24px;
    width: 250px;
  }
  &__container {
    ul.shade-list-vto {
      li.vto-filter {
        .vto-filter-text {
          width: 100%;
        }
      }
    }
  }
  &__mobile-ctrl {
    .container {
      .product-size {
        font-size: 14px;
      }
    }
  }
  &__overlay-title {
    font-size: 2em;
    font-weight: 700;
  }
}
