.password-sent-page,
.password-reset-page {
  &__content {
    padding: 0 10px;
  }
}

.profile-info__fieldset {
  .picker-radio {
    float: left;
    width: 25%;
  }
  .gender_col,
  .birthday_col {
    label {
      display: block;
    }
  }
  div.birthday_col {
    select,
    .select2-container {
      margin-bottom: 10px;
    }
  }
}

.state_container {
  label {
    display: block;
  }
}

.postal_code_container {
  .address-form {
    &__item {
      width: 45%;
      float: left;
      margin-right: 1em;
    }
  }
  .help_container {
    clear: both;
    float: none;
    padding: 10px;
    width: 100%;
  }
}

.site-container {
  min-height: 500px;
  .grid-container {
    .account-nav__section {
      #profile-upload-photo {
        white-space: nowrap;
      }
    }
    @include breakpoint($bp--medium-up) {
      .grid--item {
        &:nth-of-type(4n + 1) {
          width: 30%;
        }
        &:nth-of-type(4n + 2) {
          width: 45%;
          margin-#{$ldirection}: 30%;
        }
      }
    }
  }
  .past-purchases {
    .product__footer {
      a.notify_me {
        @include swap_direction(margin, 0);
        bottom: 15px;
        #{$rdirection}: 18%;
      }
      .product-item__out-of-stock {
        bottom: 0px;
        @include swap_direction(margin, 0);
        float: #{$rdirection};
        #{$rdirection}: 25%;
        top: 17px;
      }
    }
  }
  .favorites-page {
    .grid--mpp {
      .product__footer {
        a.notify_me {
          @include swap_direction(margin, 0);
          bottom: 32px;
          #{$rdirection}: 14%;
        }
        .product-item__out-of-stock {
          float: #{$rdirection};
          position: relative;
          top: 10px;
          #{$rdirection}: 22%;
        }
      }
    }
  }
  .account-order-history {
    .product__detail {
      width: 24% !important;
      margin-#{$ldirection}: 9% !important;
    }
    .past-purchases {
      &__item {
        .product-header {
          .quantity {
            margin-#{$ldirection}: 28%;
          }
          .rating {
            margin-#{$ldirection}: 43%;
            width: 16%;
            @include breakpoint($bp--medium-down) {
              @if $fonts_update == true {
                padding-right: 0;
              }
            }
          }
          .price {
            margin-#{$rdirection}: 30%;
            width: auto;
          }
          @if $fonts_update == true {
            @include breakpoint($bp--medium-down) {
              font-size: 9px;
            }
            @include layout(5 3 3 2 5, 1em) {
              h6 {
                @include breakpoint($bp--medium-down) {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
        .product--teaser {
          .product__qty {
            margin-#{$ldirection}: 28%;
          }
          .product__rate {
            margin-#{$ldirection}: 43%;
            width: 14.11111%;
          }
          .product__price {
            margin-#{$ldirection}: 60%;
            width: 22.11111%;
            @if $fonts_update == false {
              font-size: 1rem !important;
            }
          }
          .product__add {
            width: 23%;
            &-to-bag {
              width: 100% !important;
              @include swap_direction(padding, 5px 0 !important);
              font-size: 0.7rem !important;
              line-height: 24px !important;
              height: 34px !important;
            }
          }
        }
      }
    }
  }
  .account-nav {
    .account-utilities,
    .account-purchases {
      .sidebar-menu {
        font-size: 14px;
        .logout {
          margin: 35px 0 10px 0;
        }
      }
    }
  }
}

#mobile-navigation {
  .field-menu {
    .menu_search_img {
      top: 28px;
      left: 15px;
    }
  }
}

.sidr {
  li {
    &.mm-whats-new-items-mobile-menu {
      .menu {
        .menu-item-container {
          padding-top: 0;
          .mm-whats-new-items-mobile-contents {
            display: none;
          }
        }
      }
    }
  }
}

.order-totals {
  tr {
    &.order-details-lineitems {
      border-top: 1px solid #dbdbdb;
    }
    &.total-with-tax {
      border-top: 1px solid #000;
    }
    &.tax-above {
      border-bottom: 1px solid #000;
    }
  }
}

.replenshiment-page {
  &__content {
    .replenishment-detail {
      &-page {
        .replenishment__products {
          @include breakpoint($bp--small-down) {
            padding: 1em;
          }
        }
        .shipping-frequency {
          @include breakpoint($bp--small-down) {
            padding: 1em;
            .btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.add-payment-page {
  &__content {
    @include breakpoint($bp--small-down) {
      .payment-form {
        padding: 1em;
      }
      .payment-submit {
        width: 100%;
      }
    }
  }
}

.order-details-page {
  &__content {
    .panel {
      padding: 1em;
    }
  }
}

.address-form {
  form[name='address'] {
    .form-item {
      width: 49%;
      padding-right: 1%;
      display: inline-block;
      margin-bottom: 1em;
    }
    .city_container {
      float: right;
    }
    .address1_container {
      width: 49%;
      position: absolute;
      padding: 2px 15px 0 0;
      .form-item {
        width: 100%;
      }
    }
    .address2_container {
      width: 49%;
      float: right;
      padding-right: 2px;
      .form-item {
        width: 100%;
      }
    }
    .postal_code_container {
      input[name='POSTAL_CODE'] {
        width: 60%;
      }
    }
  }
}

.profile-page {
  form[name='profile_preferences'] {
    fieldset.profile-info__fieldset {
      margin-top: 10px;
    }
  }
}

.profile-page,
.address__content,
.add-payment-page__content {
  .adpl {
    input[type='email'].js-label-mode + label:before,
    input[type='tel'].js-label-mode + label:before,
    input[type='password'].js-label-mode + label:before,
    input[type='text'].js-label-mode + label:before {
      content: attr(data-required) attr(alt);
    }
    input[type='email'] + label:before,
    input[type='tel'] + label:before,
    input[type='password'] + label:before,
    input[type='text'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
}

.add-payment-page__content {
  .adpl {
    .form-item {
      margin-bottom: 15px;
    }
    .card_expiry_date {
      @include breakpoint($bp--small-down) {
        select.expiry_month {
          width: 47%;
        }
        select.expiry_year {
          width: 47%;
          float: right;
          position: relative;
          top: -65px;
        }
      }
      @include breakpoint($bp--small-up) {
        select.expiry_year {
          margin-top: 17px;
        }
      }
    }
  }
}
