.checkout {
  .cart-item {
    &__qty,
    &__price {
      &-label {
        display: none !important;
      }
    }
    &__remove-form a,
    .remove-sample {
      @include body-mono();
    }
    &__price {
      @extend .product__price;
    }
    &__total {
      width: auto;
      padding-right: 0.5em;
      margin-left: 79.33333%;
    }
  }
  &__sidebar {
    .product {
      background: $color--gray--white;
      padding: 1em;
    }
  }
}

.cart-item {
  // breaking this out, for better DRY implementation
  &.sample &__remove-form {
    .checkout & {
      // changing the way the below is implemented, to provide the easiest way to keep inline content in this element's
      // container on a different line from the remove sample link (a sibling of this element, and an inline element),
      // when this element shouldn't shown
      //display: none;
      visibility: hidden;
      height: 0;
    }
  }
}

.select2 {
  &-container &-choice &-arrow {
    b {
      .checkout__content .product & {
        @include icon(caret-down);
        font-size: 24px;
        &:before {
          @include transform(rotate(-45deg));
        }
      }
    }
  }
}
