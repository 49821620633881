@if $password_strengthen {
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($bp--medium-up) {
        bottom: 20%;
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 2%;
        width: 185px;
        &-checkout {
          bottom: 0;
        }
        &-book_appoint {
          bottom: auto;
          -webkit-transform: translateY(-92%);
          -moz-transform: translateY(-92%);
          -o-transform: translateY(-92%);
          transform: translateY(-92%);
          position: absolute;
          #{$ldirection}: 64%;
        }
      }
    }
    &__rules {
      li {
        color: $color--gray--dark;
      }
    }
  }
  .password-reset-page {
    .password-reset {
      .password-field {
        &__info {
          &-reset {
            @include breakpoint($bp--medium-up) {
              bottom: auto;
              #{$ldirection}: 320px;
              top: -22px;
            }
          }
        }
      }
    }
  }
  .appt-book-section {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          #{$ldirection}: 60%;
          bottom: auto;
          position: absolute;
          transform: translateY(-92%);
          -webkit-transform: translateY(-92%);
          -moz-transform: translateY(-92%);
          -o-transform: translateY(-92%);
        }
      }
    }
    .booking-step {
      .password-field {
        &__info {
          @include breakpoint($bp--medium-up) {
            #{$ldirection}: 55%;
            bottom: auto;
            position: absolute;
            transform: translateY(-92%);
            -webkit-transform: translateY(-92%);
            -moz-transform: translateY(-92%);
            -o-transform: translateY(-92%);
          }
        }
      }
    }
  }
}
