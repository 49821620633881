.margin_top_bottom {
  @include swap_direction(margin, 0.8em 0 0.8em 0);
}

#mobile-navigation {
  .field-menu {
    .menu_search {
      width: 75%;
      padding: 10px 40px;
      margin-right: 10px;
    }
    .menu_search_btn {
      background-color: #4d4d4d;
      padding: 10px;
      height: 36px;
      line-height: 1px;
    }
    .menu_search_img {
      position: absolute;
      top: 40px;
      font-size: 20px;
      left: 30px;
      color: #4d4d4d;
    }
  }
}

input {
  line-height: 1.5;
}

.site-container {
  .page--spp__product {
    .product__footer {
      a.notify_me {
        @include swap_direction(padding, 0px 20px);
        width: 100%;
        position: static;
        font-size: 1.5em;
        height: 60px;
        line-height: 64px;
      }
      .product__inventory-status {
        li.temp-out-of-stock {
          .temp-out-of-stock__text {
            top: 0;
          }
        }
      }
    }
    .shade-picker {
      &__tools-filters {
        &-toggle,
        &-clear {
          font-size: 18px;
        }
      }
    }
  }
  .product__edd {
    display: none;
  }
  .store-locator {
    .sub-options {
      display: none;
    }
  }
}

.waitlist-iframe-wrapper {
  @include swap_direction(margin, 17px 7px 5px 0);
}

.tiny-waitlist-overlay {
  .field {
    .email_input {
      width: 70%;
    }
  }
}

.site-footer--top {
  .footer-social--mobile {
    .social-links {
      .social--set-icons_part {
        display: inline-block;
        margin-top: 12px;
      }
      .line__icon {
        &:before {
          content: '';
          background: url(../fonts/LINE.svg) 0 0 no-repeat;
          display: inline-block;
          width: 23px;
          height: 23px;
          position: relative;
          top: 7px;
        }
      }
      .gplus__icon {
        &:before {
          content: '';
          background: url(../fonts/GOOGLE_PLUS.svg) 0 0 no-repeat;
          display: inline-block;
          width: 30px;
          height: 20px;
          position: relative;
          top: 4px;
        }
      }
      .cosme__icon {
        &:before {
          content: '';
          background: url(../fonts/COSME.svg) 0 0 no-repeat;
          display: inline-block;
          width: 95px;
          height: 20px;
          position: relative;
          top: 6px;
        }
      }
    }
  }
  @if $fonts_update == false {
    .block--footer-mobile-help {
      .btn {
        line-height: 1;
      }
    }
  }
}

.product--full {
  .product__rating {
    top: 18px;
    right: 14px;
    width: auto;
    &--no_rating {
      top: 0;
    }
  }
}

.custom-grid {
  .carousel {
    .slick-dots {
      bottom: 8px;
    }
    &.carousel--small-controls {
      .carousel-controls {
        bottom: 20px;
      }
    }
  }
}

.uncheck_email_newsletter {
  margin: 0 0 0 40px;
  color: #888;
}
/* BIS/ OOS notification overlay style */

.waitlist-iframe-wrapper {
  width: 100%;
  height: 90%;
}

.tiny-waitlist-overlay {
  height: 312px;
  .waitlist_header,
  .waitlist_thankyou_heading {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 5px;
  }
  .email_input {
    width: 80%;
    float: left;
    clear: none;
  }
  .submit_input {
    float: left;
    clear: none;
  }
  .input-btn {
    height: 68px;
    margin-left: 10px;
  }
  .terms_condition_cont {
    text-align: right;
    position: relative;
    top: 10px;
    width: 100%;
    float: right;
  }
}
/* End  BIS/ OOS notification overlay */

/*  Inventory-status notification btn align  */
.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        @include breakpoint($bp--small-up) {
          a.notify_me {
            width: auto;
            padding: 0 15px;
            line-height: 30px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

.store-locator {
  .results_panel {
    .results_side {
      .door_info {
        .distance {
          display: none;
        }
      }
    }
    .locations_map_panel {
      .infoBox {
        .location_info {
          .distance {
            display: none;
          }
        }
      }
    }
  }
}
/*  End  Inventory-status notification  */

.site-container {
  .collection-detail-formatter {
    .prod_inv_status-3 {
      .product__footer {
        .product__inv-status-item {
          &.coming-soon-text {
            position: static;
            height: 60px;
            margin: 0;
          }
        }
      }
    }
  }
}

.device-mobile {
  .zdc_popup {
    .zdc {
      &__popup {
        &--content {
          width: auto;
          margin-#{$ldirection}: 70px;
        }
      }
    }
  }
}

.gift-options-panel {
  .giftwrap {
    .sub-section {
      .btn {
        min-width: unset;
        width: auto;
      }
    }
  }
}

.viewcart {
  .column {
    .panel {
      .form-item {
        label {
          width: 14%;
          margin-bottom: 1px;
        }
      }
    }
  }
}
