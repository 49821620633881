.macselect-benefits__section-levels {
  .macselect-benefits__section-copy {
    ul {
      li {
        flex-direction: column;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}

.loyalty__romance {
  &__with__rewards__details {
    .loyalty__lover {
      .loyalty__level__1 {
        .benefits__details {
          &__offers {
            width: 90%;
            .item__content {
              color: $color--full-white;
            }
          }
          .level__name,
          .level__text,
          .learn__more-link {
            color: $color--full-white;
          }
        }
      }
    }
    .loyalty__level__1 {
      .benefits__details {
        bottom: 0;
        @include breakpoint($bp--large-up) {
          bottom: 30%;
        }
      }
    }
    .loyalty__level__2,
    .loyalty__level__3 {
      .benefits__details__offers {
        width: 100%;
      }
    }
  }
}

#loyalty__page__index-bottom {
  visibility: visible;
}

.loyalty__content-bottom {
  &__section-header {
    display: none;
  }
}

.site-container {
  .account-nav__section {
    .sidebar-menu {
      position: relative;
      .sidebar-menu__item {
        &.logout {
          position: absolute;
          top: 0;
          margin: 0;
          #{$ldirection}: 140px;
        }
      }
    }
  }
}

.loyalty-select__bottom,
.loyalty__content-bottom {
  .mac-select__marketing-page {
    .macselect-benefits__container__content {
      .levels {
        height: auto;
      }
      &__left {
        .macselect-benefits__container__content {
          &__level1,
          &__level2,
          &__level3 {
            .lover__container {
              position: absolute;
              top: 50%;
              left: 0%;
              transform: translateX(-22%) translateY(-50%) rotate(-90deg);
              display: table;
              width: 200%;
              .macselect-benefits__container__content {
                &-level,
                &-status {
                  text-align: start;
                }
              }
            }
          }
          &__level1 {
            height: 450px;
          }
          &__level2 {
            height: 375px;
          }
          &__level3 {
            height: 525px;
          }
        }
      }
      &__right {
        .macselect-benefits__container__content {
          &__level1,
          &__level2,
          &__level3 {
            ul {
              li {
                height: 75px;
              }
            }
          }
        }
      }
    }
    .macselect-benefits__container {
      .macselect-benefits {
        &__section-levels__header-title {
          letter-spacing: -1px;
        }
      }
    }
  }
}

.loyalty__panel__offers {
  &__sku__list-container {
    .tabbed-rewards-block__tab {
      width: 17%;
    }
  }
}

.loyalty__index__container {
  .loyalty__panel__offers {
    &__list-container,
    &__sku__list-container {
      .loyalty__panel__offer {
        &__title__details,
        &__sku__title__details {
          text-align: center;
          font-size: 16px;
          font-family: $ano-black_regular-font;
          font-weight: 700;
          text-transform: uppercase;
          span {
            border-bottom: 1px solid $color--gray--lighter;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

.mac-select__account-panel {
  &__header-left {
    float: #{$ldirection};
  }
  &__header-right {
    display: flex;
    flex-direction: column;
    position: absolute;
    #{$rdirection}: 50px;
  }
  &__content-spend,
  &__content-processing {
    clear: both;
  }
  &__content {
    position: relative;
  }
}

.account-nav {
  .account-nav__section {
    .mac-select__account-panel__content-title {
      width: 100%;
    }
  }
}

.site-footer--sticky {
  .sticky-footer-links-formatter__links {
    .mac-select-link {
      .drawer-formatter__trigger {
        color: $color--full-white;
      }
    }
  }
}

.customer-service__page-content {
  h2 {
    line-height: 1.3;
  }
  h5 {
    line-height: 1;
  }
  p {
    line-height: 1.5;
    letter-spacing: 2px;
  }
}

.sticky-footer-links-formatter {
  li {
    &.no-bar {
      border-#{$ldirection}: 2px solid $color--full-white;
    }
  }
}

body {
  &.toolbar-drawer,
  &.elc-user-state-logged-in {
    padding-top: 0 !important;
  }
}

.is-loyalty {
  .marketing__redeem__rewards {
    display: none;
  }
}

#confirmation-page {
  .column {
    .registration-panel-loyalty {
      display: flex;
      flex-direction: column;
      @include breakpoint($bp--medium-up) {
        flex-direction: row;
      }
      .panel--loyalty-join__logo-section {
        width: 50%;
        text-align: center;
        margin-top: 5%;
        .panel--loyalty-join__logo {
          .loyalty__logo {
            width: 220px;
            height: 180px;
          }
        }
      }
    }
  }
}

.checkout-panel--loyalty-join {
  .loyalty-message {
    margin-top: 40px;
  }
}

#loyalty__panel__offers {
  .loyalty__panel__offers {
    &__list-container {
      margin-top: 0;
    }
    &__loader__button {
      &.button--disabled {
        color: $color--full-white;
      }
    }
  }
}

#loyalty__panel__transactions {
  .loyalty__panel__transactions__list__column-spent {
    &.expando-menu-trigger {
      background-position: 10px;
    }
  }
  .loyalty__panel__transactions__list {
    .loyalty__panel__transactions__table {
      tbody {
        tr.expando-menu__parent--expanded {
          .expando-menu-trigger {
            background-position: 10px;
          }
        }
      }
    }
  }
  .loyalty__panel__transactions__title {
    font-weight: 700;
  }
}

a.loyalty__panel__offers__sku__loader__button,
button.loyalty__panel__offers__sku__loader__button {
  &.loyalty__panel__product__button {
    &.button {
      &.btn-shopnow {
        color: $white;
      }
    }
  }
  &.loyalty__panel__offers__sku__remove {
    &.button--offers {
      &.button {
        color: $white;
      }
    }
  }
}

.checkout__content {
  .registration-panel-loyalty {
    &.panel {
      border: 0;
    }
    .checkout__panel-content {
      .btn {
        height: 36px;
        @include breakpoint($bp--medium-up) {
          height: 45px;
        }
      }
    }
  }
  .confirmation-panel__actions {
    .return-link-container {
      @include breakpoint($bp--medium-up) {
        margin-#{$ldirection}: 42%;
        width: 58%;
      }
    }
  }
}

.mac-lover-content {
  .loyalty-message {
    padding-top: 20px;
  }
}

.loyalty__panel__offers__list {
  .loyalty__panel__offers {
    &__product__sub-header {
      margin-bottom: 10px;
    }
  }
}

.product-full__short-desc {
  @include breakpoint($bp--medium-up) {
    text-transform: uppercase;
    color: $color--text;
  }
}

.mac-select__marketing-page {
  &__top-col1 {
    @include breakpoint($bp--medium-up) {
      width: 65%;
    }
  }
  &__top-col2 {
    @include breakpoint($bp--medium-up) {
      width: 35%;
    }
  }
}
